import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'
import { deleteObjectSettings } from 'src/endpoints'
import styled from 'styled-components'

import { Button, DialogWrapper, RemoveModal } from '@/components'
import { Flex } from '@/components/Layout'
import { CheckBoxFormField } from '@/components/UberForm'
import { ObjectSettingsDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { LightTableColumn } from '@/types'

import { ObjectSettingsModal } from '../components/ObjectSettingsModal'

export const useObjectSettingsColumns = (handleSave: () => void) => {
	const { t } = useAppContext()

	const columns = useMemo<LightTableColumn<ObjectSettingsDto>[]>(
		() => [
			{
				field: 'name',
				title: t('NAME'),
				width: 50,
			},
			{
				field: 'code',
				title: t('CODE'),
				width: 50,
				maxWidth: 300,
			},
			{
				field: 'structureType',
				title: t('ELEMENT'),
				width: 50,
			},
			{
				field: 'comment',
				title: t('TABLE_GENERAL_COMMENT'),
				width: 50,
			},
			{
				field: 'description',
				title: t('TABLE_GENERAL_DESCRIPTION'),
				width: 50,
			},
			{
				field: 'defaultFlag',
				title: t('DEFAULT'),
				maxWidth: 80,
				width: 60,
				tdStyle: { justifyContent: 'center' },
				formatter: (_, os) => (
					<Checkbox disabled name="default" initialValue={os.defaultFlag} />
				),
			},
			{
				field: '',
				width: 60,
				maxWidth: 60,
				formatter: (_, objectSettings) => (
					<Flex>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<ObjectSettingsModal
										objectSettings={objectSettings}
										onSave={handleSave}
										onClose={onClose}
										isEditModal
									/>
								)
							}
						>
							{(onClick) => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon title={t('EDIT')} icon={faEdit} />
								</Button>
							)}
						</DialogWrapper>
						{objectSettings.defaultFlag != true && (
							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<RemoveModal
											callback={deleteObjectSettings}
											onRemove={handleSave}
											removeId={objectSettings.id}
											textOnModal={t('REMOVE_DESC', [objectSettings.name])}
											header={t('REMOVE_OBJECT_SETTINGS')}
											onClose={onClose}
										/>
									)
								}
							>
								{(onClick) => (
									<Button size="sm" schema="transparent" onClick={onClick}>
										<FontAwesomeIcon
											title={t('REMOVE')}
											color="#d00"
											icon={faTimes}
										/>
									</Button>
								)}
							</DialogWrapper>
						)}
					</Flex>
				),
			},
		],
		[handleSave, t],
	)

	return columns
}

const Checkbox = styled(CheckBoxFormField)`
	flex: 0;
	margin: 0;
`
