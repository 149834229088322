import React, { useCallback, useMemo, useState } from 'react'

import { CodeInputFormless } from '@/components/CodeInput/CodeInput'
import { PropertyFieldProps } from '@/components/Properties/components/types'
import { RegExpValidatedInputFormless } from '@/components/RegExpValidatedInput/RegExpValidatedInput'
import { TableIndexColumn } from '@/endpoints/schemas'
import { ItemPropertyType, Position } from '@/enums'
import { SortIcon } from '@/pages/User/pages/Home/pages/TableDetail/components/SortIcon'
import {
	listValue,
	listValues,
} from '@/pages/User/pages/Home/pages/TableDetail/utils'

import { ColumnsPicker } from '../../ColumnsPicker/ColumnsPicker'
import { AceEditor } from './inputs/AceEditor'
import { Checkbox } from './inputs/Checkbox'
import { ForeignKey } from './inputs/ForeignKey/ForeignKey'
import { TextArea } from './inputs/TextArea'
import { ErrorTooltip, FormInput, Select } from './PropertyFieldStyles'
import { ValueWrapper } from './ValueWrapper'

export const PropertyField = <T,>({
	autoFocus,
	error,
	fromProps,
	item,
	listOfValues,
	nonUserChange,
	onChange,
	onDisableEditing,
	prop,
	readonly,
	refCheckbox,
	value,
}: PropertyFieldProps<T>) => {
	const [focused, setFocused] = useState(false)
	const onFocus = useCallback(() => setFocused(true), [])

	const onBlur = useCallback(() => {
		setFocused(false)
	}, [])

	const changeHandler = (fieldValue: any) => {
		if (item && onChange) {
			return onChange({
				fieldId: item.id,
				fieldName: prop.field,
				fieldValue: fieldValue,
				rowIndex: -1,
				uuid: item.uuid,
			})
		}
	}

	const handleChange = useCallback(
		(fieldValue: any) => {
			if (nonUserChange) {
				return
			}

			if (fieldValue === undefined || fieldValue === null) return value

			return changeHandler(fieldValue)
		},
		[changeHandler, nonUserChange, value],
	)

	const options = useMemo(() => {
		if (typeof prop.options === 'function') {
			return prop.options(item)
		}

		if (Array.isArray(prop.options)) {
			return prop.options
		}

		return []
	}, [prop, item])

	const isDisabled = useMemo(
		() =>
			typeof prop.disabled === 'function'
				? prop.disabled(item, listOfValues)
				: typeof prop.disabled === 'boolean'
					? prop.disabled
					: false,
		[item, listOfValues, prop],
	)

	const regExp = prop.regExp?.(item)

	if (prop.type === ItemPropertyType.CHECKBOX) {
		// render Checkbox because we want to change it on click in both readonly and editing modes
		return (
			<Checkbox
				checked={typeof value === 'boolean' ? value : false}
				name={prop.field}
				onChange={(e) => handleChange(e.target.checked)}
				disabled={isDisabled}
				onFocus={onFocus}
				onBlur={onBlur}
				error={error}
				label={fromProps ? prop.label : ''}
				isFocused={focused}
				refCheckbox={refCheckbox}
				fromProps={fromProps}
			/>
		)
	}

	if (prop.type === ItemPropertyType.GENERATED_CODE) {
		return (
			<ErrorTooltip
				shown={!!error && focused}
				showOnHover={false}
				content={error}
				position={Position.Bottom}
			>
				<CodeInputFormless
					value={typeof value === 'string' ? value : ''}
					name={prop.field}
					onChange={(fieldValue) => handleChange(fieldValue)}
					onFocusCustom={onFocus}
					onBlurCustom={onBlur}
					autoFocus={autoFocus}
					error={error}
					readonly={readonly}
					disabled={isDisabled || !prop.canGenerateCodeFieldName}
					readonlyContainer={(value) => (
						<ValueWrapper disabled={isDisabled}>{value}</ValueWrapper>
					)}
				/>
			</ErrorTooltip>
		)
	}

	return !readonly && !prop.isLoading ? (
		<>
			{prop.type === ItemPropertyType.TEXT && (
				<ErrorTooltip
					shown={!!error && focused}
					showOnHover={false}
					content={error}
					position={Position.Bottom}
				>
					<FormInput
						value={
							typeof value === 'string' || typeof value === 'number'
								? value
								: ''
						}
						type="text"
						name={prop.field}
						autoComplete="off"
						onChange={(event) => handleChange(event.target.value)}
						disabled={isDisabled}
						onFocus={onFocus}
						onBlur={onBlur}
						$isFocused={focused}
						error={error}
						autoFocus={autoFocus}
						$fromProps={fromProps}
					/>
				</ErrorTooltip>
			)}

			{prop.type === ItemPropertyType.REGEXP_VALIDATED && (
				<RegExpValidatedInputFormless
					value={typeof value === 'string' ? value : ''}
					name={prop.field}
					enableAutocomplete={false}
					onChange={() => console.log('tbf')}
					disabled={isDisabled}
					expression={regExp?.expression}
					sampleValue={regExp?.sampleValue}
				/>
			)}
			{prop.type === ItemPropertyType.TEXTAREA && (
				<TextArea
					value={typeof value === 'string' ? value : ''}
					onChange={(_, value: any) => handleChange(value)}
					disabled={isDisabled}
					onFocus={onFocus}
					onBlur={onBlur}
					autoFocus={autoFocus}
					isFocused={focused}
					fromProps={fromProps}
					prop={prop}
					onDisableEditing={onDisableEditing}
				/>
			)}

			{prop.type === ItemPropertyType.ACE_EDITOR && (
				<AceEditor
					value={value}
					disabled={isDisabled}
					autoFocus={autoFocus}
					isFocused={focused}
					fromProps={fromProps}
					prop={prop}
					onDisableEditing={onDisableEditing}
					{...prop.aceEditorProps}
					onFocus={onFocus}
					onBlur={onBlur}
					onChange={(name, value: any) => handleChange(value)}
				/>
			)}

			{prop.type === ItemPropertyType.FOREIGN_KEY && (
				<ForeignKey
					value={value}
					name={prop.field ?? ''}
					onChange={handleChange}
					disabled={isDisabled}
					onFocus={onFocus}
					onBlur={onBlur}
					fromProps={fromProps}
					foreignKeyTableId={prop.foreignKeyTableId}
					primaryKeys={prop.primaryKeys}
					possibleForeignKeys={prop.possibleForeignKeys}
					error={error}
				/>
			)}
			{prop.type === ItemPropertyType.OPTION && (
				<Select
					fromProps={!!fromProps}
					name={prop.field || ''}
					options={options}
					hideTitle={true}
					initialValue={value}
					onChange={(fieldValue) => handleChange(fieldValue)}
					disabled={isDisabled}
					valueKey={prop.optionValue}
					labelKey={prop.optionLabel}
					isNumeric={prop.isNumeric}
					clearable={prop.clearable}
					allowEmpty
					formless
					loading={!!prop.isLoading}
					onFocus={onFocus}
					onBlur={onBlur}
					error={error}
					autoFocus={autoFocus}
					openMenuOnFocus
					placeholder=""
				/>
			)}
			{prop.type === ItemPropertyType.OPTIONS && (
				<Select
					fromProps={!!fromProps}
					name={prop.field || ''}
					options={options}
					hideTitle={true}
					initialValue={value}
					onChange={handleChange}
					disabled={isDisabled}
					valueKey={prop.optionValue}
					labelKey={prop.optionLabel}
					isNumeric={prop.isNumeric}
					clearable={prop.clearable}
					isMulti
					allowEmpty
					formless
					loading={!!prop.isLoading}
					onFocus={onFocus}
					onBlur={onBlur}
					error={error}
					autoFocus={autoFocus}
					openMenuOnFocus
					placeholder=""
				/>
			)}

			{(prop.type === ItemPropertyType.COLUMNS ||
				prop.type === ItemPropertyType.COLUMNS_WITH_ORDER) && (
				<ColumnsPicker
					field={prop.field || ''}
					columns={options}
					value={value ?? []}
					onChange={handleChange}
					orders={prop.type === ItemPropertyType.COLUMNS_WITH_ORDER}
				/>
			)}
		</>
	) : (
		<>
			{(prop.type === ItemPropertyType.TEXT ||
				prop.type === ItemPropertyType.NUMBER ||
				prop.type === ItemPropertyType.REGEXP_VALIDATED) && (
				<ValueWrapper title={value} disabled={isDisabled}>
					{value}
				</ValueWrapper>
			)}
			{(prop.type === ItemPropertyType.TEXTAREA ||
				prop.type === ItemPropertyType.ACE_EDITOR) &&
				(fromProps ? (
					value
				) : (
					<ValueWrapper title={value} disabled={isDisabled}>
						{value}
					</ValueWrapper>
				))}
			{prop.type === ItemPropertyType.OPTION && (
				<ValueWrapper
					disabled={isDisabled}
					title={listValues(
						options,
						prop.optionValue || 'value',
						prop.optionLabel || 'label',
						[value],
					)}
				>
					{listValue(
						options,
						prop.optionValue || 'value',
						prop.optionLabel || 'label',
						value,
					)}
				</ValueWrapper>
			)}

			{prop.type === ItemPropertyType.OPTIONS && (
				<ValueWrapper
					disabled={isDisabled}
					title={listValues(
						options,
						prop.optionValue || 'value',
						prop.optionLabel || 'label',
						Array.isArray(value) ? value : [],
					)}
				>
					{listValues(
						options,
						prop.optionValue || 'value',
						prop.optionLabel || 'label',
						Array.isArray(value) ? value : [],
					)}
				</ValueWrapper>
			)}
			{prop.type === ItemPropertyType.FOREIGN_KEY && (
				<ForeignKey
					value={value}
					name={prop.field ?? ''}
					onChange={handleChange}
					disabled={isDisabled}
					onFocus={onFocus}
					onBlur={onBlur}
					fromProps={fromProps}
					primaryKeys={prop.primaryKeys}
					possibleForeignKeys={prop.possibleForeignKeys}
					error={error}
					readonly
				/>
			)}
			{prop.type === ItemPropertyType.COLUMNS && (
				<ValueWrapper
					disabled={isDisabled}
					title={listValues(
						options,
						'code',
						'name',
						Array.isArray(value) ? value.map((v) => v.code) : [],
					)}
				>
					{listValues(
						options,
						'code',
						'name',
						Array.isArray(value) ? value.map((v) => v.code) : [],
					)}
				</ValueWrapper>
			)}
			{prop.type === ItemPropertyType.COLUMNS_WITH_ORDER &&
				Array.isArray(value) &&
				(value as TableIndexColumn[]).map((ci, index) => (
					<React.Fragment key={ci.code}>
						<SortIcon sortType={ci.sortType} />
						<span>
							{options.find((o) => o.id === ci.code)?.name ?? ci.code}
						</span>
						{index != value.length - 1 && ', '}
					</React.Fragment>
				))}
		</>
	)
}
