import { faBox, faPlus } from '@fortawesome/free-solid-svg-icons'

import { Button, Container, DialogWrapper, LightTable } from '@/components'
import { getAllObjectSettings } from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import { ObjectSettingsDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { useSettingsPageSaveHandler } from '@/pages/User/pages/Settings/utils'

import { useFilter } from '../../hooks/useFilter'
import {
	TitleActions,
	TitleContainer,
	TitleIcon,
	TitleText,
} from '../../styles'
import {
	ObjectSettingsFilter,
	ObjectSettingsFilterValues,
} from './components/ObjectSettingsFilter'
import { ObjectSettingsModal } from './components/ObjectSettingsModal'
import { useObjectSettingsColumns } from './hooks/useObjectSettingsColumns'
import { useObjectSettingsFilteredData } from './hooks/useObjectSettingsFilteredData'

interface ObjectSettingsProps {
	textAreaHeight?: string
}

export const ObjectSettings = ({ textAreaHeight }: ObjectSettingsProps) => {
	const { t } = useAppContext()
	const objectSettings = useApi(getAllObjectSettings())
	const { filter, onChangeFilter } = useFilter<ObjectSettingsFilterValues>()

	const handleSave = useSettingsPageSaveHandler(objectSettings)

	const columns = useObjectSettingsColumns(handleSave)

	const objectSettingsFiltered = useObjectSettingsFilteredData(
		filter,
		objectSettings.data,
	)

	return (
		<>
			<TitleContainer>
				<TitleIcon icon={faBox} color="#602655" />
				<TitleText>{t('OBJECT_SETTINGS')}</TitleText>
				<TitleActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<ObjectSettingsModal
									textAreaHeight={textAreaHeight}
									onSave={handleSave}
									onClose={onClose}
									isEditModal={false}
								/>
							)
						}
					>
						{(onClick) => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>
			<Container>
				<ObjectSettingsFilter onChangeFilter={onChangeFilter} />
				<LightTable<ObjectSettingsDto>
					data={objectSettingsFiltered}
					columns={columns}
					keyField={'id'}
					isLoading={objectSettings.loading}
				/>
			</Container>
		</>
	)
}
