import debounce from 'debounce'
import { useRef } from 'react'
import {
	createObjectSettings,
	getStereotypeTypesForObjectSettings,
	updateObjectSettings,
} from 'src/endpoints'

import { CodeInput } from '@/components'
import { ForwardedModalForm } from '@/components/Modal/ForwardedModalForm'
import {
	CheckBoxFormField,
	Form,
	FormField,
	SelectFormField,
	TextAreaFormField,
	TextFormField,
} from '@/components/UberForm'
import { useApi, useApiRequest } from '@/endpoints/hooks'
import {
	ObjectSettingsCreateDto,
	ObjectSettingsDto,
	ObjectSettingsUpdateDto,
} from '@/endpoints/models'
import { useAppContext, useAppDispatch } from '@/hooks'

import { AuditForm } from '../../../components/AuditForm'
import { auditFieldsAdd, auditFieldsRemove, onNameChange } from '../../../utils'

interface ObjectSettingsModalProps {
	isEditModal: boolean
	objectSettings?: Partial<ObjectSettingsDto>
	onClose: () => void
	onSave: () => void
	textAreaHeight?: string
}

export const ObjectSettingsModal = ({
	objectSettings,
	onSave,
	onClose,
	textAreaHeight,
	isEditModal,
}: ObjectSettingsModalProps) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const request = useApiRequest()
	const elements = useApi(getStereotypeTypesForObjectSettings())
	const refForm = useRef<Form<ObjectSettingsDto>>(null)

	const handleSubmit = async (form: Partial<ObjectSettingsDto>) => {
		auditFieldsRemove(form)

		const action =
			objectSettings && objectSettings.id
				? updateObjectSettings(
						objectSettings.id,
						form as ObjectSettingsUpdateDto,
					)
				: createObjectSettings(form as ObjectSettingsCreateDto)

		const result = await request(action)

		if (result) {
			onSave()
			onClose()
		}
	}

	const debouncedOnNameChange = debounce(onNameChange, 500)

	return (
		<>
			<ForwardedModalForm<ObjectSettingsDto>
				open={true}
				onClose={onClose}
				contentStyle={{
					width: '550px',
				}}
				initialValues={{
					id: objectSettings?.id,
					name: objectSettings?.name,
					code: objectSettings?.code,
					structureType: objectSettings?.structureType,
					description: objectSettings?.description,
					comment: objectSettings?.comment,
					...auditFieldsAdd(objectSettings),
				}}
				header={
					objectSettings
						? t('UPDATE_OBJECT_SETTINGS')
						: t('CREATE_OBJECT_SETTINGS')
				}
				onSubmit={handleSubmit}
				ref={refForm}
			>
				<TextFormField
					title={t('NAME')}
					name="name"
					required
					onChange={(v) =>
						debouncedOnNameChange(dispatch, refForm, v as string)
					}
				/>
				<FormField name="code" title={t('CODE')}>
					<CodeInput name="code" readonly={!isEditModal} />
				</FormField>
				<SelectFormField
					title={t('ELEMENT')}
					name="structureType"
					options={elements.data || []}
					fieldIsLoading={elements.loading}
					valueKey="code"
					labelKey="code"
					clearable={false}
					required
				/>
				<TextAreaFormField
					textAreaHeight={textAreaHeight}
					title={t('DESCRIPTION')}
					name="description"
				/>
				<TextAreaFormField
					textAreaHeight={textAreaHeight}
					title={t('COMMENT')}
					name="comment"
				/>
				<CheckBoxFormField
					title={t('DEFAULT')}
					name="defaultFlag"
					formless
					disabled
					initialValue={objectSettings?.defaultFlag}
				/>
				<AuditForm $isUpdating={!!objectSettings} />
			</ForwardedModalForm>
		</>
	)
}
