import { useMemo } from 'react'

import { CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX } from '@/constants'
import { CustomAttribute } from '@/endpoints/models'
import { ItemPropertyType } from '@/enums'
import { useAppStore } from '@/hooks'
import { PropertiesErrorProps, TableColumnProps } from '@/types'

export const valueGet = <T>(prop: TableColumnProps<T>, item: T) => {
	if (prop.value) {
		return prop.value(item)
	}

	if (prop.field) {
		return (item as any)?.[prop.field]
	}

	return undefined
}

export const valueOrLabelGet = <T>(prop: TableColumnProps<T>, item: T) => {
	if (prop.type === ItemPropertyType.OPTION) {
		let options: any[] = []

		if (typeof prop.options === 'function') {
			options = prop.options(item)
		} else {
			options = prop.options ?? []
		}

		const value = valueGet(prop, item)

		const option = options.find(
			(option) => option[prop.optionValue ?? ''] === value,
		)

		return option?.[prop.optionLabel ?? ''] ?? undefined
	}

	return valueGet(prop, item)
}

export const errorGet = <T>(
	properties: TableColumnProps<T>[],
	prop: TableColumnProps<T>,
	item: T | undefined,
	errors?: PropertiesErrorProps,
) => {
	// TODO: Maybe add id property as mandatory to item type to fix any?
	return prop.field && errors?.[(item as any).id]?.[prop.field]?.message
}

export const usePropertiesPanel = (nodeId?: number, tabKey?: string) => {
	const openedTabs = useAppStore((state) => state.tab.openedTabs)

	const tab = useMemo(
		() => openedTabs.find((t) => t.nodeId === nodeId),
		[openedTabs, nodeId],
	)

	const propertiesPanel = useMemo(
		() => (tabKey ? tab?.propertiesShown[tabKey] : undefined),
		[tab, tabKey],
	)

	return {
		propertiesHidden: !propertiesPanel?.shown,
		propertiesWidth: propertiesPanel?.width,
	}
}

export const parsePanelPropCA = (
	customAttributesAll: any,
	formValues: any,
): CustomAttribute[] => {
	// Filter truthy props and exclude empty arrays
	const filteredObject = Object.fromEntries(
		Object.entries(formValues).filter(([key, value]) => {
			const isNonEmptyArray = Array.isArray(value) && value.length > 0
			const isTruthy = !!value && !Array.isArray(value)

			// Keep if value is strictly false or if it's either truthy or a non-empty array
			return isNonEmptyArray || isTruthy
		}),
	)

	return Object.keys(filteredObject)
		.filter((key) => key.includes(CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX))
		.map((key) => {
			const [_, id] = key.split('.')
			const customAttributeId = parseFloat(id)

			const matchedCustomAttribute = customAttributesAll.data?.find(
				(customAttribute: any) => customAttribute.id === customAttributeId,
			)

			return {
				id: String(customAttributeId),
				name: matchedCustomAttribute?.name,
				value: formValues[key],
				version: String(matchedCustomAttribute?.version ?? 1),
			}
		})
}
